import { createTheme } from "@material-ui/core/styles"


export const theme = createTheme ({
    palette: {
        primary: {
            main: "#702F8A"
        },
        secondary: {
            main: "#C6ABD0"
        }
    },
    typography: {
        fontFamily: "'Myriad W01', sans-serif",
    },
    shape: {
        borderRadius: 10,
    },

    spacing: 10,
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",  
                padding: "10px",    
                minWidth:"30px",
            },
            fullWidth: {
                maxWidth: "300px"
            
            }
        }
    },
    props: {
        MuiButton: {
            //disableRipple: true,
            variant: 'contained',
            color: "primary",
            
            style: {padding:10, margin: 10},
            
        },
        MuiCheckbox: {
            disableRipple: true,
        },
    
        MuiTextField: {
           
            variant: 'outlined',
            InputLabelProps: {
                shrink: true,
            },
            style: {margin:10},
        },
        MuiPaper: {
            elevation: 12,
        },
        MuiCard: {
            elevation: 12,
        }
    }
})