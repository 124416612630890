import { Grid } from '@material-ui/core';
import { DigitalStaticURL, NPLTimeText } from '../Constants';
import '../css/application.css';
import '../css/site.css';

export default function Home() {
  return <Grid className="middleContainer">
    <Grid item xs={12}>
      <img src={`${DigitalStaticURL}/app-home-npltime.png`} alt="" width="100%" min-height="100%" />
      <div id="hompagetext">
        <NPLTimeText /> services by NPL offer access to terrestrial, UTC-traceable and resilient timing signals to help users across
        multiple sectors meet their timing requirements
      </div>
    </Grid>
  </Grid>
}
