import { useAccount, useMsal } from '@azure/msal-react';
import { Box, Button, FormGroup, TextField, DialogContent, Dialog, DialogContentText, DialogActions } from "@material-ui/core";
import { Field, Form, Formik } from 'formik';
import {ddOptions} from "../models/helper";
import { protectedResources } from '../authconfig';
import agent from '../api/agent'
import { DuplicateReport } from '../models/DuplicateReport';
import { useState } from 'react';

export default function DuplicateFeeReportDialog(props: any) {
    const {onClose, onUpdateGrid} = props;

    interface IPeriod {
        value: number;
        label: string;
    }

    const periods:IPeriod[] = [
        {value: 1, label: 'Q1 (January - March)' },
        {value: 2, label: 'Q2 (April - June)' },
        {value: 3, label: 'Q3 (July - September)' },
        {value: 4, label: 'Q4 (October - December)' },
    ];

    const[completed, setCompleted] = useState<boolean>(false);

    let years: ddOptions[] = [];
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) || undefined;

    for (let i = 2021; i <= new Date().getFullYear(); i++) {
        years.push({id: i, name: i.toString()});
    }

    const handleClose = () => {
        UpdateGrid();
        setCompleted(false);
        onClose();
    };

    const UpdateGrid = () => {
        onUpdateGrid();
    }

    const duplicateReport: DuplicateReport = {
        userId:'',
        fromYear: new Date().getFullYear(),
        fromQuarter: 0,
        toYear: new Date().getFullYear(),
        toQuarter: 0
    }

    return (
        <>
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Formik initialValues={duplicateReport}
                    enableReinitialize={true}
                    onSubmit={(values, formikHelpers) => {
                        console.log("here");

                        console.log(values);
                        console.log(formikHelpers);
                        formikHelpers.setSubmitting(false)

                        instance.acquireTokenSilent({
                            scopes: protectedResources.apiNPLTime.scopes,
                            account: account
                        }).then((response) => {
                            console.log("after acquiring token");
                            console.log(values);

                            if(account) {
                                values.userId = account?.localAccountId;
                            }

                            agent.report.duplicateQuarter(values, response.accessToken)
                            .then((res) => {
                                console.log(res);

                                setCompleted(true);
                            });
                        });
                    }}
                >
                    {({values, errors, isSubmitting}) => (
                        <Form>
                            <Box marginBottom={3}>
                                <FormGroup>

                                    <Field name="fromYear" as={TextField} select label="From Year" style={{width:200}}>
                                        {years && years.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })}
                                    </Field>

                                    <Field name="fromQuarter" as={TextField} select label="From Quarter">
                                        {periods.map((period, key) => {
                                            return <option key={key} value={period.value}>{period.label}</option>
                                        })}
                                    </Field>

                                    <Field name="toYear" as={TextField} select label="To Year" style={{width:200}}>
                                        {years && years.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })}
                                    </Field>

                                    <Field name="toQuarter" as={TextField} select label="To Quarter">
                                        {periods.map((period, key) => {
                                            return <option key={key} value={period.value}>{period.label}</option>
                                        })}
                                    </Field>

                                </FormGroup>
                            </Box>
                            <Box marginBottom={3}>
                                <FormGroup>
                                    <Button type="submit" className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall submitbtn toppad">Submit</Button>
                                </FormGroup>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>

        <Dialog
            open={completed}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                The reports for the selected period have been duplicated.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                OK
            </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}
