import { useAccount, useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import agent from "../api/agent";
import { protectedResources } from "../authconfig";
import { CircularProgress } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import { ddOptions } from "../models/helper";

interface Props {
    year: number;
    month: number;
    endpoint: number;
    location: number;
    distributor: number;
    file: number;
}

const PTPChart = (props: Props) => {
    const[datasetsHC, setDataSetsHC] = useState<Highcharts.Options>({});
    const[loadingState, setLoadingState] = useState<boolean>(true);
    const[errorState, setErrorState] = useState<boolean>(false);
    const[locations, setLocations] = useState<ddOptions[]>([]);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) || undefined;

    const GetGraph = useCallback(() => {
        if(account && locations.length > 0 ) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            }).then((response) => {
                agent.time.GetPTPData(
                    props.year,
                    props.month,
                    props.endpoint,
                    props.location,
                    props.distributor,
                    props.file,
                    account.localAccountId,
                    response.accessToken
                )
                .then((resp) => {
                    if (resp.length > 0) {
                        let data: [Date, number][] = [];
                        let chartTitle: string = ( locations.find(x => x.id === resp[0].location)?.name ?? '' ) + `-${resp[0].endpoint}`;
                        let chartType: string = (props.file === 1) ? 'PTP Offset' : 'PTP Path Delay';

                        //resp.sort((a, b) => (new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()));

                        resp.forEach(e => {
                            data.push([ new Date(e.timestamp), e.value ]);
                        });

                        // https://api.highcharts.com/highcharts/
                        setDataSetsHC({
                            title: {
                                text: `${chartType} - ${chartTitle}`,
                            },
                            series: [{
                                name: chartType,
                                type: 'line',
                                data: data,
                                color: "#002f6c",
                            }],
                            yAxis: {
                                title: { text: `${chartType} / ns` },
                            },
                            xAxis: {
                                title: { text: "Date" },
                                type: 'datetime',
                                labels: { format: '{value:%Y/%m/%d }' },
                            },
                            tooltip: {
                                formatter: function() {
                                    const xVal = this.x as number;
                                    return `${Highcharts.dateFormat('%Y-%m-%d, %H:%M:%S UTC', xVal, true)}<br/>${this.series.name}: <b>${this.y}</b>`;
                                },
                            },
                            chart: {
                                height: "600px",
                                zoomType: "x",
                            },
                            credits: {
                                enabled: false
                            },
                        });

                        setLoadingState(false);
                    } else {
                        setErrorState(true);
                    }
                });
            });
        }
    }, [account, instance, locations, props.year, props.month, props.distributor,
        props.endpoint, props.location, props.file
    ]);

    useEffect(() => {
        if(account) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            }).then((response) => {
                // TODO - Method that works for multiple distributions.
                agent.report.getLocations(1, response.accessToken)
                .then((l:ddOptions[]) => (setLocations(l)));
            });
        }
    }, [account, instance]);

    useEffect(() => { GetGraph() }, [GetGraph]);

    if (errorState) {
        return (<i>No data was found.</i>);
    } else if (loadingState) {
        return (<CircularProgress />);
    } else {
        Boost(Highcharts); // Greatly improves the load time of huge datasets.

        return(
            <HighchartsReact
                highcharts={Highcharts}
                options={datasetsHC}
            />
        );
    }
}

export default PTPChart;
