import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Button, Container, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { DataGrid, GridCellParams, GridCellValue } from "@material-ui/data-grid";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import agent from "../api/agent";
import { protectedResources } from "../authconfig";
import { NPLTimeText } from "../Constants";
import { eUserType } from "../Enums";
import { Distribution, LocationEndPoint, Location } from "../models/filter";
import { PTPDeviceLog } from "../models/PTPDeviceLog";
import { UserContext } from "../UserContext";

export default function PTPDeviceLogHome() {
    const pageName = "Event Log";
    const [deviceLogs, setDeviceLogs] = useState<PTPDeviceLog[]>([]);
    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(true);
    const account = useAccount(accounts[0] || {}) || undefined;
    const { user } = useContext(UserContext);

    const[distributors, setDistributors] = useState<Distribution[]>([]);
    const[locations, setLocations] = useState<Location[]>([]);
    const[endpoints, setEndpoints] = useState<LocationEndPoint[]>([]);
    const[selectedDistributor, setSelectedDistributor] = useState<number>(0);
    const[selectedLocation, setSelectedLocation] = useState<number>(0);
    const[selectedEndPoint, setSelectedEndPoint] = useState<number>(0);
    const navigate = useHistory();

    if (user.userType === eUserType.AccessUser) {
        navigate.push('/');
    }

    useEffect(() => { document.title = `${pageName} - NPL Time` }, []);

    const tableStyling = makeStyles({
        root: {
          "& .MuiDataGrid-columnsContainer": {
            maxHeight: "65px!important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            overflow: "visible",
            lineHeight: "1rem",
            whiteSpace: "normal",
          }
        }
    });

    const GetLogs = useCallback(() => {
        if(account) {
            setLoading(true);
            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            }).then((response) => {
                agent.deviceLog.GetAllLogs(selectedDistributor, selectedLocation, selectedEndPoint, response.accessToken)
                    .then(feeReportResponse => {
                        setLoading(false);
                        console.warn(feeReportResponse);
                        const allLogs: PTPDeviceLog[] = feeReportResponse.map(report => {
                            const distributorName = report.distributor === 0 ? "All" : report.distributorName
                            return {...report, distributorName: distributorName}
                        })
                        setDeviceLogs(allLogs);
                });
            });
        }
    }, [account, instance, selectedDistributor, selectedLocation, selectedEndPoint]);

    useEffect(() => { GetLogs() }, [GetLogs]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getDistributions(response.accessToken)
            .then((res) => (setDistributors(res)));
        });
    }, [account, instance]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getLocations(selectedDistributor, response.accessToken)
            .then((res) => (setLocations(res)));
        });
    }, [account, instance, selectedDistributor]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getLocationEndpoints(selectedDistributor, selectedLocation, response.accessToken)
            .then((res) => (setEndpoints(res)));
        });
    }, [account, instance, selectedDistributor, selectedLocation]);

    function DeleteFeeReport(LogID: number) {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.deviceLog.delete(LogID, response.accessToken)
            .then(() => {
                alert("Log entry has been deleted.");
                GetLogs();
            });
        });
    }

    const columns = [
        { field: 'logNumber', flex:0.5, headerName: 'Log Number',
            renderCell: (params: GridCellParams) => (
                <div>
                    <Typography>{params.value}</Typography>
                    <Typography color="textSecondary">{params.getValue(params.id, "distributorRef")}</Typography>
                </div>
            )
        },
        { field: 'timestamp', flex:0.5, headerName: 'Entry Timestamp',
            renderCell: (params: GridCellParams) => {
                let dt = new Date(params.value?.toString() ?? '');
                return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
            }
        },
        { field: 'distributorName', flex:0.3, headerName: 'Distributor' },
        { field: 'endPoint', flex:0.5, headerName: 'Location', renderCell: (params: GridCellParams) => {
            let location = (params.getValue(params.id, "locationId") === 0) ? 'All Locations' : params.getValue(params.id, "location");
            let endpoint = (params.getValue(params.id, "endPoint") === 0) ? '' : ` - ${params.getValue(params.id, "endpointName")}`; 
            return `${location}${endpoint}`;
        }},
        { field: 'comments', flex:0.75, headerName: 'Comment(s)'},
        { field : 'edit', flex:0.5, hide: (user.userType === eUserType.NPLAdmin) ? false : true, headerName:'Actions', renderCell: (params: GridCellParams) => {
            if (user.userType === eUserType.NPLAdmin) {
                const DeleteLog = () => {
                    let documentId: GridCellValue = params.getValue(params.id, "id");
                    DeleteFeeReport(documentId != null ? +documentId : 0);
                }

                return (
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }}
                            href={`/CreateLog?id=` + params.id}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }}
                            onClick={DeleteLog}
                        >
                            Delete
                        </Button>
                    </div>
                );
            }
        }}
    ];

    return(
        <div>
            <Box className='layout'>
                <Container fixed>
                    <Grid container className="titlePadding">
                        <Grid item xs={12} className="title"><NPLTimeText /></Grid>
                    </Grid>
                </Container>
            </Box>
            <Container className="middleContainer" style={{ marginTop:10, marginBottom:10}}>
            <Typography component="h1" style={{ fontSize: '1.75em', fontWeight: 'bold' }}>{pageName}</Typography>
                <p>
                    The Event log page is used to report any events or incidents on specific end point devices.  Use the column headings
                    to sort the data in the table accordng to date, location, log number, etc. For any further information on any of the
                    events listed below, please contact <a href="mailto:npltimetechnical@npl.co.uk">npltimetechnical@npl.co.uk</a> quoting
                    the log number linked to the event.
                </p>

                <h4>Filters</h4>
                <Grid container style={{ marginBottom: 20 }}>
                    <Grid item xs={3}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel id="distributor-select">Distributor</InputLabel>
                            <Select
                                id="distributor-select"
                                value={selectedDistributor}
                                onChange={(event:any) => {
                                    setSelectedDistributor(event.target.value);
                                    setSelectedLocation(0);
                                    setSelectedEndPoint(0);
                                }}
                            >
                                <MenuItem key={0} value={0}>All</MenuItem>
                                {distributors && distributors.map((e:Distribution, key:number) => {
                                    return <MenuItem key={key} value={e.id}>{e.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel id="location-select">Location</InputLabel>
                            <Select
                                id="location-select"
                                value={selectedLocation}
                                onChange={(event:any) => {
                                    setSelectedLocation(event.target.value);
                                    setSelectedEndPoint(0);
                                }}
                                disabled={(selectedDistributor === 0)}
                            >
                                <MenuItem key={0} value={0}>All</MenuItem>
                                {locations && locations.map((e:Location, key:number) => {
                                    return <MenuItem key={key} value={e.id}>{e.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="standard" style={{ minWidth: 120 }}>
                            <InputLabel id="endpoint-select">Endpoint</InputLabel>
                            <Select
                                id="endpoint-select"
                                value={selectedEndPoint}
                                onChange={(event:any) => {
                                    setSelectedEndPoint(event.target.value);
                                }}
                                disabled={(selectedLocation === 0)}
                            >
                                <MenuItem key={0} value={0}>All</MenuItem>
                                {endpoints && endpoints.map((e:LocationEndPoint, key:number) => {
                                    return <MenuItem key={key} value={e.id}>{e.endPoint}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <DataGrid rowHeight={70} style={{height:500}}
                    className={tableStyling().root}
                    getRowId={(row) => row.id}
                    rows={deviceLogs}
                    columns={columns}
                    loading={loading}
                    pagination
                />

                {(user.userType === eUserType.NPLAdmin) ?
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        //className="btn"
                        style={{ marginTop:10, marginBottom:10}}
                        href={`/CreateLog`}
                    >
                        Create Log
                    </Button>
                : null}
            </Container>
        </div>
    );
}
