import { useAccount, useMsal } from "@azure/msal-react";
import { FeeReport } from "../models/FeeReport";
import agent from "../api/agent";
import { useState, useEffect, useContext }  from "react";
import { Field, Form, Formik } from 'formik';
import { protectedResources } from "../authconfig";
import { Box, Button, Container, FormGroup, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { ddOptions } from '../models/helper'
import { useLocation } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import { number, object, string, date } from 'yup'
import { eFeeReportStatus, eUserType } from "../Enums";
import { UserContext } from "../UserContext";
import { NPLTimeText } from "../Constants";
import { Distribution } from "../models/filter";

// enum used to check distributor IDs against the ones returned by agent.report.getDistributions()
enum distributorIDs {
    ICE = 1,
    UBS = 2,
    Telehouse = 3,
}

export default function CreateFeeReport(props: any)
{
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) || undefined;
    const[locations, setLocations] = useState<ddOptions[]>([]);
    const [distributor, setDistributor] = useState<Distribution>({} as Distribution)
    const[selectedCustomerType, setSelectedCustomerType] = useState<number>(1);
    // const { user, setUser }  = useContext(UserContext);
    const {search} = useLocation();
    const[dataSaved, setDataSaved] = useState(false);
    const { user }  = useContext(UserContext);
    const navigate = useHistory();

    if (user.userType === eUserType.Admin || user.userType === eUserType.AccessUser) {
        navigate.push('/');
    }

    let feeReportId: string | null = new URLSearchParams(search).get('id');
    let initialValues: FeeReport = {
        id: 0,
        distributorName: '',
        location:'',
        customerType: 1,
        customerName: '',
        locationId: 1,
        quarter: '1',
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        numberOfPorts: 1,
        numberOfCertifiedEndPointDevices: 0,
        numberOfSecondaryDistributionPoints: 0,
        mrc: 0,
        distributorOrderNumber: '0',
        firstBillingMonth: 1,
        monthlyNPLFee: 0,
        numberOfMonths: 0,
        total: 0,
        userId: '',
        finalTotal: 0,
        totalFeeForEndpointDevices: 0,
        totalFeeForRedistributionPoints: 0,
        status: 0,
    }
    const[feeReport, setFeeReport] = useState<FeeReport>(initialValues);

    const feeReportValidationSchema = object().shape({
        customerType: number(),
        customerName: string().required("Customer name must be specified."),
        locationId: string().required("Location must be specified."),
        quarter: number().required("Quarter must be specified."),
        numberOfPorts: string().required("Solution type must be specified."),
        mrc: number().when('customerType', {
            is: 1,
            then: number().min(1).required("Monthly recurring costs must be specified."),
        }),
        numberOfCertifiedEndPointDevices: number().when('customerType', {
            is: 2,
            then: number().min(1).required(),
        }),
        numberOfSecondaryDistributionPoints: number().when('customerType', {
            is: 2,
            then: number().min(1).required(),
        }),
        numberOfMonths: number().when('customerType', {
            is: 1,
            then: number().min(1).required(),
        }),
        firstBillingMonth: number().when('customerType', {
            is: 2,
            then: number().min(1).required(),
        }),
        distributorOrderNumber: string().when('customerType', {
            is: 1,
            then: string().required(),
        }),
        billingDate: date().when('customerType', {
            is: 1,
            then: date().required(),
        }),

    });

    useEffect(() => {
        document.title =  ((feeReportId === null || feeReportId === "0") ? 'Create' : 'Edit') + " Fee Reports - NPL Time";
    }, [feeReportId]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getDistributions(response.accessToken)
            .then(distributions => {
                // users with company ICE or UBS have two distributors. We want to only use ICE for fee reports
                let selectedDistribution: Distribution; 
                if(distributions.includes({id: 1, name: "ICE"}) && distributions.includes({id: 2, name: "UBS"})) {
                    selectedDistribution = distributions.find(dist => dist.id === distributorIDs.ICE)! //NON-NULL ASSERTION: we know that ICE is in the list of distributors if code execution reaches this point
                } else selectedDistribution = distributions[0]
                setDistributor(selectedDistribution)
            });
        
        });
    }, [account, instance, user.companyId]);

    // Gets the list of locations for the user's distributor
    useEffect(() => {
        if(distributor.id !== undefined) {
            (async () => {
                const token = await instance.acquireTokenSilent({
                    scopes: protectedResources.apiNPLTime.scopes,
                    account: account
                })

                const locations = await agent.report.getLocations(distributor.id, token.accessToken)
                setLocations(locations)
            })()
        }
    }, [account, instance, distributor])
    
    useEffect(() => {
        
        setDataSaved(false);
        //alert(feeReportId);
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
            }).then((response) => {
                console.log("after acquiring token");
                if(feeReportId != null)
                {
                    initialValues.id = parseInt(feeReportId);
                }
                // if(user.subCompanyId == 1)
                // {
                //     initialValues.distributorName = "ICE";
                // }
                // else
                // {
                //     initialValues.distributorName = "UBS";
                // }
                if(account)
                    initialValues.userId = account.localAccountId

                       if(feeReportId !== null && feeReportId !== "0")
                       {
                            agent.report.data(parseInt(feeReportId), response.accessToken)
                            .then((feeReportModel) => {
                                console.log(feeReportModel);
                                feeReportModel.billingDate      = feeReportModel.billingDate?.slice(0, 10)
                                feeReportModel.handoverDate     = feeReportModel.handoverDate?.slice(0, 10)
                                feeReportModel.cancellationDate = feeReportModel.cancellationDate?.slice(0, 10)
                                setSelectedCustomerType(feeReportModel.customerType)
                                setFeeReport(feeReportModel)
                               
                            });
                        }
                       else
                       {
                           setFeeReport(initialValues);
                       }

                    })
                // eslint-disable-next-line react-hooks/exhaustive-deps
                },[account, instance, feeReportId]);

    if(dataSaved)
    {
       
        return <div>
            {/* <AlertDialog {...alertProps} onClose={handleClose} /> */}
            <Redirect 
            to={{
                pathname: "/Reports"
                //search:`?id=${props.patientId}`
                //search: `?SessionId=${initialValues.sessionId}&PatientId=${props.patientId}`
            // state: { referrer: currentLocation }
            }} />
        </div>
    }
    return   <div> 
              {/* <AlertDialog {...alertProps} onClose={handleClose} /> */}
                <Box className='layout'>
                <Container fixed>
                        <Grid container className="titlePadding">
                            <Grid item xs={12} className="title"><NPLTimeText /></Grid>
                        </Grid> 
                </Container>
                </Box> 
                <Container className="middleContainer">
                <Typography component="h1" style={{ fontSize: '1.75em', fontWeight: 'bold' }}>Create Fee Report</Typography>
                <Typography>All fields are required.</Typography>
    <Formik initialValues={feeReport} 
                        validationSchema={feeReportValidationSchema}
                        enableReinitialize={true}
                        onSubmit={(values, formikHelpers) => {
                            console.log("here");
                        values.distributorName = "1"; 
                        console.log(values);
                        console.log(formikHelpers);
                        formikHelpers.setSubmitting(false)

                        instance.acquireTokenSilent({
                            scopes: protectedResources.apiNPLTime.scopes,
                            account: account
                            }).then((response) => {
                                console.log("after acquiring token");
                                console.log(values);
                                if(account)
                                {
                                    values.userId = account?.localAccountId;
                                }
                                agent.report.createfee(values.userId, values, response.accessToken)
                                    .then((res) => {
                                       setDataSaved(true);
                                       return <Redirect to="/Reports"  />

                                    });
                                }
                            
                            );
                    }}>
                    {({values, errors, touched, isSubmitting}) => {
                    let readonly = (user.userType !== eUserType.NPLAdmin) ? ((values.status === parseInt(eFeeReportStatus.Submitted.toString())) ? true : false) : true;
                    return( 
                    <Form> 
                    <Box marginBottom={3}>
                        <FormGroup>
                         
                           
                             {/* <Field name="distributorName" as={TextField} select label="Distributor Name" value={props.} disabled={true}>
                                <option value={1}>ICE</option>
                                <option value={2}>UBS</option>
                            </Field> */}

                            <Field
                                name="customerType"
                                as={TextField}
                                select
                                label="Customer Type"
                                value={selectedCustomerType}
                                onChange={(event:any) => {
                                    setSelectedCustomerType(event.target.value);
                                    values.customerType = event.target.value;
                                }}
                                disabled={readonly}
                            >
                                <option value={1}>Colocation Customer</option>
                                <option value={2}>Enterprise Customer</option>
                            </Field>
                        
                            <Field
                                name="customerName"
                                as={TextField}
                                label="Customer Name"
                                disabled={readonly}
                                error={errors.customerName && touched.customerName}
                                helperText={errors.customerName && touched.customerName ? errors.customerName : null}
                            ></Field>

                            <Field
                                name="locationId"
                                as={TextField}
                                select
                                label="Location"
                                disabled={readonly}
                                error={errors.locationId && touched.locationId}
                                helperText={errors.locationId && touched.locationId ? errors.locationId : null}
                            >
                            {locations && locations.map((e, key) => {
                                return <option key={key} value={e.id}>{e.name}</option>;
                            })}
                            </Field>

                            <Field
                                name="quarter"
                                as={TextField}
                                select
                                label="Quarter"
                                disabled={readonly}
                                error={errors.quarter && touched.quarter}
                                helperText={errors.quarter && touched.quarter ? errors.quarter : null}
                            >
                                <option value={1}>Q1(January - March)</option>
                                <option value={2}>Q2(April - June)</option>
                                <option value={3}>Q3(July - September)</option>
                                <option value={4}>Q4(October - December)</option>
                            </Field>
                                    
                            { values.customerType !== 1 ?  
                                <>
                                    <Field
                                        name="numberOfCertifiedEndPointDevices"
                                        as={TextField}
                                        label="# of Certified End Point Devices"
                                        type="number"
                                        disabled={readonly}
                                        error={errors.numberOfCertifiedEndPointDevices && touched.numberOfCertifiedEndPointDevices}
                                        helperText={errors.numberOfCertifiedEndPointDevices && touched.numberOfCertifiedEndPointDevices ? errors.numberOfCertifiedEndPointDevices : null}
                                    />
                                    <Field
                                        name="numberOfSecondaryDistributionPoints"
                                        as={TextField}
                                        label="# Secondary Distribution Points"
                                        type="number"
                                        disabled={readonly}
                                        error={errors.numberOfSecondaryDistributionPoints && touched.numberOfSecondaryDistributionPoints}
                                        helperText={errors.numberOfSecondaryDistributionPoints && touched.numberOfSecondaryDistributionPoints ? errors.numberOfSecondaryDistributionPoints : null}
                                    />
                                </>
                                : null
                            }

                            { values.customerType === 1 ?
                                <>
                                    <Field
                                        name="numberOfPorts"
                                        as={TextField}
                                        select
                                        label="Solution Type"
                                        disabled={readonly}
                                        error={errors.numberOfPorts && touched.numberOfPorts}
                                        helperText={errors.numberOfPorts && touched.numberOfPorts ? errors.numberOfPorts : null}
                                    >
                                        <option value={1}>Colo Non-Resilient</option>
                                        <option value={2}>Colo Resilient</option>
                                        <option value={3}>Premium Hosting</option>
                                    </Field>

                                    <Field
                                        name="mrc"
                                        as={TextField}
                                        label="MRC"
                                        type="number"
                                        InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}}
                                        disabled={readonly}
                                        error={errors.mrc && touched.mrc}
                                        helperText={errors.mrc && touched.mrc ? errors.mrc : null}
                                    >
                                    </Field>

                                    <Field
                                        name="firstBillingMonth"
                                        as={TextField}
                                        select
                                        label="First Billing Month"
                                        disabled={readonly}
                                        error={errors.firstBillingMonth && touched.firstBillingMonth}
                                        helperText={errors.firstBillingMonth && touched.firstBillingMonth ? errors.firstBillingMonth : null}
                                    >
                                        <option value={1}>January</option>
                                        <option value={2}>February</option>
                                        <option value={3}>March</option>
                                        <option value={4}>April</option>
                                        <option value={5}>May</option>
                                        <option value={6}>June</option>
                                        <option value={7}>July</option>
                                        <option value={8}>August</option>
                                        <option value={9}>September</option>
                                        <option value={10}>October</option>
                                        <option value={11}>November</option>
                                        <option value={12}>December</option>
                                    </Field>
                                </>
                                : null
                            }

                            {/* <Field name="monthlyNPLFee" as={TextField} label="Monthly NPL Fee" type="number">
                            </Field>  */}
                            
                            { values.customerType === 1 ?
                                <>
                                    <Field
                                        name="numberOfMonths"
                                        as={TextField}
                                        select
                                        label="Number of Months"
                                        disabled={readonly}
                                        error={errors.numberOfMonths && touched.numberOfMonths}
                                        helperText={errors.numberOfMonths && touched.numberOfMonths ? errors.numberOfMonths : null}
                                    >
                                        <option value={0}>Select</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                    </Field>

                                    <Field
                                        name="distributorOrderNumber"
                                        as={TextField}
                                        label="Distributor Order Number"
                                        disabled={readonly}
                                        error={errors.distributorOrderNumber && touched.distributorOrderNumber}
                                        helperText={errors.distributorOrderNumber && touched.distributorOrderNumber ? errors.distributorOrderNumber : null}
                                    />

                                    <Field
                                        name="billingDate"
                                        as={TextField}
                                        label="Billing Date"
                                        type="date"
                                        disabled={readonly}
                                        error={errors.billingDate && touched.billingDate}
                                        helperText={errors.billingDate && touched.billingDate ? errors.billingDate : null}
                                    />

                                    <Field
                                        name="cancellationDate"
                                        as={TextField}
                                        label="Cancellation Date"
                                        type="date"
                                        disabled={readonly}
                                        error={errors.cancellationDate && touched.cancellationDate}
                                        helperText={errors.cancellationDate && touched.cancellationDate ? errors.cancellationDate : null}
                                    />

                                    <Field
                                        name="handoverDate"
                                        as={TextField}
                                        label="Handover Date"
                                        type="date"
                                        disabled={readonly}
                                        error={errors.handoverDate && touched.handoverDate}
                                        helperText={errors.handoverDate && touched.handoverDate ? errors.handoverDate : null}
                                    />
                                </>
                                : null
                            }
                        
                        </FormGroup>
                    </Box>
                    <Box marginBottom={3}>
                        <FormGroup>
                            <Button
                                type="submit"
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall submitbtn toppad"
                                disabled={readonly}
                            >
                                Submit
                            </Button>
                        </FormGroup>
                    </Box>
                    {/* <pre>{JSON.stringify(values, null, 4)}</pre>  */}

                    </Form>           
                    );}}

                </Formik>
            </Container>


            </div>
}

