import { createContext } from "react";
import { NPLTimeUser } from "./models/NPLTimeUser";


export type GlobalContent = {
    user: NPLTimeUser,
    setUser:(user: NPLTimeUser) => void
  }

 export const UserContext = createContext<GlobalContent>( {
     user: {
            userId: '',
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            companyId: 0,
            subCompanyId: 0,
            companyName: '',
            subCompanyName: '',
            userType: 0,
            
        },
        setUser: (user: NPLTimeUser) => {},
    });


//  export const UserGlobalContext = () => useContext(UserContext)
