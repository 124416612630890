import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import Home from './components/Home';
import NavMenu from './components/Navmenu';
import './css/site.css';
import { Footer } from './components/Footer';

function Layout(props: any) {
    const { accounts } = useMsal();
  
    const account = useAccount(accounts[0] || {}) || undefined;
   
    //const account = accounts[0];
    console.log("account:" + account);
    console.log(props);
    // const { user, setUser } = useContext(UserContext);
   
  
    // React.useEffect(() => {
    //   console.log(user);
    //     if(account)
    //     {
    //       console.log("inside account")
    //         instance.acquireTokenSilent({
    //           scopes: protectedResources.apiNPLTime.scopes,
    //           account: account
    //         }).then((response) => {
    //             agent.user.details(account.localAccountId, response.accessToken) //get a list of all pure components(pure and mixed), 
    //                 .then(userDataResponse => {
    //                     console.log(userDataResponse);
    //                     //if(userDataResponse.userType === 999)
    //                     //{
    //                         setUser(userDataResponse);
    //                    // }
    //                 })
    //           }); 
    //     }
    // }, []);

  
         
        
         console.log("child:" + props);
         let SubLayout =   <div> 
                              
                                <UnauthenticatedTemplate>
                                    <Home /> 
                                </UnauthenticatedTemplate>
                                <AuthenticatedTemplate>
                                
                                 
                                  {props.children}
                                </AuthenticatedTemplate>  
                            </div>;
  
        
           
      return (
        <div>
          <NavMenu /> 
        
            {SubLayout} 
    
          <Footer /> 
        </div>
      );
    }
  
  
  //Layout.contextType = AuthContext;
  export default Layout;
  