import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertProps } from '../models/alert'

export default function AlertDialog(props: AlertProps) {
  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

 // const [open, setOpen] = React.useState(props.open);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  if(props.messageType === 4) {
    return (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title"></DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <h2>{props.title}</h2>
        <ul style={{listStyleType: 'none'}}>
          <li>01 = Primary Timing Device</li>
          <li>02 = Secondary Timing Device</li>
        </ul>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
       {/*  <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>*/}
      </DialogActions>
    </Dialog>);
  } else if(props.messageType === 3) {
  return (<Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {/* <DialogTitle id="alert-dialog-title"></DialogTitle> */}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      <h2>{props.title}</h2>
      <ul style={{listStyleType: 'none'}}>
        <li>B5P = Basildon</li>
        <li>LD4 = Equinix LD4</li>
        <li>IX = Interxion</li>
        <li>TLH = Telehouse</li>
      </ul>


      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        OK
      </Button>
     {/*  <Button onClick={handleClose} color="primary" autoFocus>
        Agree
      </Button>*/}
    </DialogActions>
  </Dialog>);
} else if(props.messageType === 2) {
  return (<Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title"></DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <h2>{props.title}</h2>
                  <h3>PTP Offset</h3>
                  <p>These graphs indicate the amount of time the PTP signal was unavailable, at each endpoint. If nothing is displayed, this indicates 100% availability. An outage of four hours or less does not affect traceability. Where incidents are shown, these will be described and explained below the graph.</p>

                  <h3>PTP Path Delay</h3>
                  <p>PTP Path Delay indicates the time taken for the PTP signal to get from the NPL hub to the endpoint, calculated as half the round-trip time. This is measured by PTP Delay Request messages, which are continually exchanged between the endpoint and its grandmaster. The value itself is not important, only deviations indicating a potential change of network topology and therefore network asymmetry. A single deviation of less than 300 ns is not considered to affect traceability, however multiple deviations, or a single large change, may cause loss of traceability and necessitate recalibration of the endpoint. A certain amount of variability is to be expected; however, large changes may indicate a problem.</p>


              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                OK
              </Button>
             {/*  <Button onClick={handleClose} color="primary" autoFocus>
                Agree
              </Button>*/}
            </DialogActions>
          </Dialog>);

              } else {
            return (<Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                OK
              </Button>
              {/* <Button onClick={handleClose} color="primary" autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
        );
}
}
