import { useAccount, useMsal } from '@azure/msal-react';
import { Field, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react'
import { protectedResources } from '../authconfig';
import agent from "../api/agent";
import { DataFilter, Distribution, Location, LocationEndPoint } from "../models/filter";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, FormGroup, Grid, TextField, Typography } from "@material-ui/core";
import { ddOptions } from "../models/helper";
import { NPLTimeData } from '../models/NPLTimeData';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import AdminHome  from '../components/AdminHome';

import LoadingComponent from '../components/LoadingComponent'
import { UserContext } from '../UserContext';
import "../css/site.css";
import "../css/application.css";
import AlertDialog from './Alert';
import { AlertValues } from '../models/alert';
import { number, object } from 'yup';
//import axios from 'axios';
import HubChart from './HubChart';
import PTPChart from './PTPChart';
import { eUserType } from '../Enums';
import { DigitalStaticURL, NPLTimeAccess, NPLTimeCertified, NPLTimeText } from '../Constants';

const initialValues: DataFilter = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    distribution: 0,
    location: 0,
    endpoint: 0,
    userId: "",
    fileId: 0,
    companyName: "",
}

export default function UserHome(props: any) {
    console.log("properties" + props.stitle);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) || undefined;
    const { user } = useContext(UserContext);
    //const[isAdmin, setIsAdmin] = useState(false);

    console.log(user);
    let years: ddOptions[] = [];

    const [loading, setLoading] = useState(false);

    const PTPAvail: string = "This parameter displays how well the internal OCXO is tracking the PTP signal. It is an indicator of the stability of the PTP signal.";
    const PTPDelay: string = "PTP Path Delay indicates the time taken for the PTP signal to get from the NPL hub to the endpoint, calculated as half the round trip time. This is measured by PTP Delay Request messages, which are continually exchanged between the endpoint and its grandmaster. The value itself is not important, only deviations indicating a potential change of network topology and therefore network asymmetry. A single deviation of less than 300 ns is not considered to affect traceability, however multiple deviations, or a single large change, may cause loss of traceability and necessitate recalibration of the endpoint. A certain amount of variability is to be expected; however, large changes may indicate a problem.";
    const ClockSync: string = "These graphs display the offset between the incoming PTP signal and the internal OCXO oscillator, for each endpoint. It is an indicator of the amount of jitter on the PTP signal. The oscillator is continually tracking the incoming signal, and will smooth out small amounts of jitter so that it does not get passed on to the customer side.";

    const [fileInfoText, setFileInfoText] = useState("");
    const [fileInfoTitle, setFileInfoTitle] = useState("");

    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [endpoints, setEndpoints] = useState<LocationEndPoint[]>([]);

    const [selectedDistribution, setSelectedDistribution] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(0);
    const [selectedEndpoint, setSelectedEndpoint] = useState(0);
    const [selectedFile, setSelectedFile] = useState(0);

    useEffect(() => {
        document.title = "Service Status Reports - NPL Time";
    }, []);

    useEffect(() => {
        // console.log("inside react effect");
        //console.log(user);


        if (account) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            }).then((response) => {
                agent.report.getDistributions(response.accessToken)
                    .then((data: Distribution[]) => {
                        setDistributions(data);
                    });

                if (selectedDistribution !== 0) {
                    agent.report.getLocations(selectedDistribution, response.accessToken)
                        .then((data: Location[]) => {
                            setLocations(data);
                        });
                }

                if (selectedDistribution !== 0 && selectedLocation !== 0) {
                    agent.report.getLocationEndpoints(selectedDistribution, selectedLocation, response.accessToken)
                        .then((data: LocationEndPoint[]) => {
                            setEndpoints(data);
                        });
                }
            });
        }
    }, [account, instance, user.userId, user.subCompanyId, user.userType,
        selectedDistribution, selectedEndpoint, selectedLocation
    ]);

    const [itemData, setItemData] = useState<NPLTimeData>();
    //const [isDataAvailable, setIsDataAvailable] = useState(false);

    let currentYear = new Date().getFullYear();
    for (let i = currentYear - 1; i <= currentYear; i++) {
        years.push({ id: i, name: i.toString() });
    }


    const ShowFileTypeInfo = () => {
        // setDialogOpen(true);
        // setMessageType(2)
        // console.log("Dialog Open flag:" + dialogOpen);
        // //alert("onclick");

        alertValues = {
            message: '',
            messageType: 2,
            title: 'File Types',
            open: true,
            //onClose: handleClose
        }

        setAlertProps(alertValues);


    };

    const ShowLocationInfo = () => {
        alertValues = {
            message: '',
            messageType: 3,
            title: 'Locations',
            open: true,
        }

        setAlertProps(alertValues);
    };

    const ShowEndpointInfo = () => {
        alertValues = {
            message: '',
            messageType: 4,
            title: 'EndPoints',
            open: true,
        }

        setAlertProps(alertValues);
    };

    const handleClose = () => {
        alertValues = {
            message: '',
            messageType: 2,
            title: 'File Types',
            open: false,
            //onClose: handleClose
        }

        setAlertProps(alertValues);
    };

    let alertValues: AlertValues = {
        message: '',
        messageType: 1,
        title: '',
        open: false,

    }

    const [alertProps, setAlertProps] = useState(alertValues);


    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(1);

    const reportValidationSchema = object({
        year: number().required('Year Required'),
        month: number().required('Month Required').min(1, 'Month Required').max(12, 'Month Required'),
        distribution: number().required('Distribution Required').min(1, 'Distribution Required'),
        location: number().required('Location Required').min(1, 'Location Required'),
        endpoint: number().required('EndPoint Required').min(1, 'EndPoint Required'),
        fileId: number().required('File Required').min(1, 'File Required'),
    })

    const handleLocationChange = (event: any) => {
        // console.log("yes");
        //alert("location changed");
        setItemData(undefined);
        setSelectedLocation(event.target.value);
        initialValues.location = event.target.value;

        setSelectedEndpoint(0);
        initialValues.endpoint = 0;
    }

    /*const DownloadPDF = () => {

        setLoading(true);
        // auth.getTokenDirect(tokenRequest).then(function(accessToken: any) {
        //     const Headers = {
        //         'Authorization': `Bearer ${accessToken}`
        //     }
        // setLoadingPDF(true);
        // //console.log(props.sessionId);
        // axios({
        //     url: process.env.REACT_APP_API_URL + '/patientsessions/' + props.sessionId + '/GetPDFReporting' , //'https://localhost:44327/api/patientsessions/0/documents/32',
        //     method: 'GET',
        //     responseType: 'blob', // important
        //     headers: Headers,
        // }).then((response) => {

        if (selectedDistribution === 0 || year === 0 || month === 0) {
            alertValues.messageType = 1;
            alertValues.message = "Distribution, Month and year should be selected in order to download PDF";
            alertValues.open = true;
            alertValues.title = "More data required";

            setAlertProps(alertValues);

            setLoading(false);

            return;

        }

        if (account) {
            // filterValues.userId = account.localAccountId;

            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            })
                .then((response) => {
                    // agent.report.downloadPDF(year, month, response.accessToken).then((resp1) => {

                    const Headers = {
                        'Authorization': `Bearer ${response.accessToken}`
                    }
                    axios({
                        url: process.env.REACT_APP_API_URL + '/Admin/GetFile/' + selectedDistribution + '/' + year + '/' + month, //'https://localhost:44327/api/patientsessions/0/documents/32',
                        method: 'GET',
                        responseType: 'blob', // important
                        headers: Headers,
                    })
                        .then((resp1) => {

                            console.log(resp1);
                            console.log(resp1.headers['Content-Disposition']);
                            if (resp1 === null || resp1 === undefined) {
                                alertValues.messageType = 1;
                                alertValues.message = "The status report couldnt be found";
                                alertValues.open = true;
                                alertValues.title = "Download status report";

                                setAlertProps(alertValues);
                            }
                            else {
                                const url = window.URL.createObjectURL(new Blob([resp1.data]));
                                console.log(resp1.data);
                                const fileName1 = resp1.headers['content-disposition'].split('"')[1];
                                // var fileName1: string | undefined = "report" + "_" + year + ".pdf";

                                const link = document.createElement('a');
                                link.href = url;
                                if (fileName1 !== undefined)
                                    link.setAttribute('download', fileName1);

                                document.body.appendChild(link);
                                link.click();
                                //console.log("inside sessions react efftec");
                                //console.log(response);
                                //setPDFReport(response);
                                // setLoadingPDF(false);
                            }

                        }).catch(err => {
                            console.log(err.message)
                            alertValues.messageType = 1;
                            alertValues.message = "File Not Found. Try a different month or contact System admin.";
                            alertValues.open = true;
                            alertValues.title = "Download status report";

                            setAlertProps(alertValues);
                        }).finally(() => setLoading(false));


                });
        }

    };*/

    const handleDistributionChange = (event: any) => {
        setItemData(undefined);
        setSelectedDistribution(event.target.value);
        initialValues.distribution = event.target.value;

        setSelectedLocation(0);
        initialValues.location = 0;
        setSelectedEndpoint(0);
        initialValues.endpoint = 0;

    }

    const handleEndpointChange = (event: any) => {
        setItemData(undefined);
        setSelectedEndpoint(event.target.value);
        initialValues.endpoint = event.target.value;
    }

    const handleFileIDChange = (event: any) => {
        setItemData(undefined);
        setSelectedFile(event.target.value);
        initialValues.fileId = event.target.value;
    }

    const onYearChange = (event: any) => {
        setItemData(undefined);
        setYear(event.target.value)
        initialValues.year = event.target.value;
    }

    const onMonthChange = (event: any) => {
        setItemData(undefined);
        setMonth(event.target.value);
        initialValues.month = event.target.value;
    }

    let imageList = <div></div>;

    if (loading)
        imageList = <LoadingComponent />
    else
        if (itemData !== undefined && itemData.imageURLs.length > 0) {
            imageList = <PTPChart
                year={year}
                month={month}
                distributor={selectedDistribution}
                endpoint={selectedEndpoint}
                location={selectedLocation}
                file={selectedFile}
            />
        }

    let webPage = <></>
    if (user.userType !== eUserType.AccessUser) {
        webPage = (<div>
            <h2><NPLTimeCertified /> End point device data</h2>
            <p>
                Use the drop down menus below to view PTP offset or PTP path delay information for specific end point devices. Data is
                updated daily.
            </p>
            <p>
                <strong>Note: This is not a report of the accuracy of the service delivered.</strong>
            </p>

            <Formik initialValues={initialValues}
                validationSchema={reportValidationSchema}
                enableReinitialize={true}
                onSubmit={(values, formikHelpers) => {
                    console.log("upon submit");
                    setLoading(true);
                    console.log("loading is" + loading);
                    // console.log(values);
                    console.log(formikHelpers);
                    formikHelpers.setSubmitting(false)
                    if (values.fileId === 1) {
                        setFileInfoTitle("PTP Offset");
                        setFileInfoText(PTPAvail);
                    }
                    else if (values.fileId === 2) {
                        setFileInfoTitle("PTP Path Delay");
                        setFileInfoText(PTPDelay);
                    }
                    else if (values.fileId === 3) {
                        setFileInfoTitle("Main Clock Sync to PTP Reference");
                        setFileInfoText(ClockSync);
                    }

                    instance.acquireTokenSilent({
                        scopes: protectedResources.apiNPLTime.scopes,
                        account: account
                    }).then((response) => {
                        setLoading(true);
                        console.log("after acquiring token");
                        if (account) {
                            values.userId = account?.localAccountId;
                        }
                        values.location = selectedLocation;
                        values.year = year;
                        values.month = month;

                        setItemData({ year: 1, month: 1, imageURLs: ['a'] });
                        setLoading(false);
                    }

                    );
                }}>
                {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                        <Box marginBottom={3}>
                            <FormGroup>
                                <h4>Filters</h4>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Field
                                            name="distribution"
                                            value={selectedDistribution}
                                            as={TextField}
                                            select
                                            label="Client"
                                            style={{ width: 200 }}
                                            onChange={handleDistributionChange}
                                            error={errors.distribution && touched.distribution}
                                            helperText={errors.distribution && touched.distribution ? errors.distribution : null}
                                        >
                                            <option value={0}>Select</option>
                                            {distributions && distributions.map((e, key) => {
                                                return <option key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field
                                            name="year"
                                            value={year}
                                            as={TextField}
                                            select
                                            label="Year"
                                            style={{ width: 200 }}
                                            onChange={onYearChange}
                                            error={errors.year && touched.year}
                                            helperText={errors.year && touched.year ? errors.year : null}
                                        >
                                            {years && years.map((e, key) => {
                                                return <option key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field
                                            name="month"
                                            value={month}
                                            as={TextField}
                                            select
                                            label="Month"
                                            style={{ width: 200 }}
                                            onChange={onMonthChange}
                                            error={errors.month && touched.month}
                                            helperText={errors.month && touched.month ? errors.month : null}
                                        >
                                            <option value={1}>January</option>
                                            <option value={2}>February</option>
                                            <option value={3}>March</option>
                                            <option value={4}>April</option>
                                            <option value={5}>May</option>
                                            <option value={6}>June</option>
                                            <option value={7}>July</option>
                                            <option value={8}>August</option>
                                            <option value={9}>September</option>
                                            <option value={10}>October</option>
                                            <option value={11}>November</option>
                                            <option value={12}>December</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field
                                            name="location"
                                            value={selectedLocation}
                                            as={TextField}
                                            select
                                            label="Location"
                                            style={{ width: 200 }}
                                            disabled={(selectedDistribution === 0)}
                                            onChange={handleLocationChange}
                                            error={errors.location && touched.location}
                                            helperText={errors.location && touched.location ? errors.location : null}
                                        >
                                            <option value={0}>Select</option>
                                            {locations && locations.map((e, key) => {
                                                return <option key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </Field>
                                        <InfoTwoToneIcon onClick={ShowLocationInfo}></InfoTwoToneIcon>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field
                                            name="endpoint"
                                            value={selectedEndpoint}
                                            as={TextField}
                                            select
                                            label="EndPoint"
                                            style={{ width: 200 }}
                                            disabled={(selectedLocation === 0)}
                                            onChange={handleEndpointChange}
                                            error={errors.endpoint && touched.endpoint}
                                            helperText={errors.endpoint && touched.endpoint ? errors.endpoint : null}
                                        >
                                            <option value={0}>Select</option>
                                            {endpoints && endpoints.map((e, key) => {
                                                return <option key={key} value={e.id}>{e.endPoint}</option>;
                                            })}
                                        </Field>
                                        <InfoTwoToneIcon onClick={ShowEndpointInfo}></InfoTwoToneIcon>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field
                                            name="fileId"
                                            value={selectedFile}
                                            as={TextField}
                                            select
                                            label="File"
                                            style={{ width: 200 }}
                                            onChange={handleFileIDChange}
                                            error={errors.fileId && touched.fileId}
                                            helperText={errors.fileId && touched.fileId ? errors.fileId : null}
                                        >
                                            <option value={0}>Select</option>
                                            <option value={1}>PTP Offset</option>
                                            <option value={2}>PTP Path Delay</option>
                                        </Field>
                                        <InfoTwoToneIcon onClick={ShowFileTypeInfo}></InfoTwoToneIcon>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Box>
                        <Box marginBottom={3}>
                            <FormGroup>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        <Button fullWidth type="submit" disabled={isSubmitting} color="primary" className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall submitbtn toppad">Submit</Button>
                                    </Grid>
                                    {/*<Grid item xs={2}>
                                        <Button fullWidth color="secondary" onClick={DownloadPDF}>Download PDF</Button>
                                    </Grid>*/}
                                </Grid>
                            </FormGroup>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 4)}</pre>  */}
                    </Form>
                )}

            </Formik>
            <div>
                <h2>{fileInfoTitle}</h2>
                <p>{fileInfoText}</p>
                {imageList}
            </div>
        </div>
        );
    }

    //if(isAdmin)
    // webPage = <AdminHome />


    if (user.userId === "")
        return <div className="min-height"></div>

    return <div>


        {/* //<AlertDialog open={dialogOpen} message={message} messageType={messageType} title={title} onClose={handleClose} /> */}
        <AlertDialog {...alertProps} onClose={handleClose} />

        <Box className='layout'>
            <Container fixed>
                <Grid container className="titlePadding">
                    <Grid item xs={12} className="title"><NPLTimeText /></Grid>
                </Grid>
            </Container>
        </Box>

        <Container className="middleContainer">
            <Typography component="h1" style={{ fontSize: '1.75em', fontWeight: 'bold' }}>Service Status Reports</Typography>

            <p>
                <NPLTimeText /> services are commercial time dissemination services that are delivered over fibre links to users and
                service providers in the UK. <NPLTimeText /> uses the UK's national time scale as the time source (managed by NPL at
                Teddington) and is delivered on a dark fibre link to the NPL service nodes (Telehouse and Daisy, Reading) and then on
                to users and/or service providers.
            </p>

            <Accordion style={{ marginTop: 5, marginBottom: 5 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>More Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                    <p>
                        The <NPLTimeText /> service includes a holdover caesium clock at both service nodes. In the case of a fault on the main
                        fibre link from Teddington, the caesium clock will automatically become the reference for any connected timing devices.
                        The caesium clock is disciplined daily to maintain alignment with the PTP time signal from UTC(NPL) to within tens of ns.
                        The clock stability of approximately 10 ns/day ensures that the UTC-traceable timing service is kept well within the
                        ±1 μs to UTC(NPL) SLA for several months (independent of GPS). This automatic holdover clock adds resiliency to the &nbsp;
                        <NPLTimeText /> service.
                    </p>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='h6' component='p' style={{ color: '#012f6b' }}><NPLTimeCertified /></Typography>
                            <img src={`${DigitalStaticURL}/npltime_certified_graph.jpg`} alt="" style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6' component='p' style={{ color: '#012f6b' }}><NPLTimeAccess /></Typography>
                            <img src={`${DigitalStaticURL}/npltime_access_graph.jpg`} alt="" style={{ width: "100%" }} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <h2><NPLTimeAccess /></h2>
            <p>
                The <NPLTimeAccess /> service offers users access to UTC(NPL) from the two NPL Service nodes in Telehouse, North and
                Daisy, Reading. The specifications of the <NPLTimeAccess /> service are 1μs accuracy to UTC(NPL) and 99.9% availability.
                The graphs below compare the incoming signal from NPL Teddington (1PPS) to the outgoing signal from each service node (PTP).
                The comparison provides not only confidence with regards to signal availability but also demonstrates accuracy to UTC(NPL) at
                each node.
            </p>

            <p>
                Use the cursor to view data for a specific time period in the graph then click the "Reset zoom" button to reset the graph.
                Data is updated on a daily basis.
            </p>

            <p><strong>Note: This is not a report of the accuracy of the service delivered.</strong></p>

            <Box my={2}>
                <HubChart />
            </Box>

            {webPage}

        </Container>

    </div>
}


