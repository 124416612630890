import { useAccount, useMsal } from "@azure/msal-react";
import { Grid, Box, Button, Container, FormControl, FormControlLabel, FormLabel,
    InputLabel, MenuItem, Radio, RadioGroup, Select, makeStyles, Tooltip, Typography,
} from "@material-ui/core";
import { DataGrid, GridCellParams, GridCellValue, GridColumnHeaderParams } from "@material-ui/data-grid";

import { useContext, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import agent from "../api/agent";
import { protectedResources } from "../authconfig";
import { FeeReport } from "../models/FeeReport";
import { feeReportFilter } from "../models/filter";
import { ddOptions } from "../models/helper";
import { eFeeReportStatus, eUserType } from "../Enums";

import "../css/site.css";
import { UserContext } from "../UserContext";
import DuplicateFeeReportDialog from './DuplicateFeeReport'
import { NPLTimeText } from "../Constants";

export default function FeeReportComponent()
{
    const[feeReports, setFeeReports] = useState<FeeReport[]>([]);
    //const feeReports1: FeeReport[] = [];
    const { instance, accounts } = useMsal();
    const[loading, setLoading] = useState(false);
    const { user }  = useContext(UserContext);
    const navigate = useHistory();

    useEffect(() => {
        document.title = "Fee Reports - NPL Time";
    }, []);
    
    const account = useAccount(accounts[0] || {}) || undefined;
    const filterValues: feeReportFilter =  {
        customerType:1,
        year:new Date().getFullYear(),
        quarter:0,
        distributor:0,
        userId: '',
    }

    if (user.userType === eUserType.Admin || user.userType === eUserType.AccessUser) {
        navigate.push('/');
    }

    const[filters, setFilters] = useState<feeReportFilter>(filterValues);

    const[customerType, setCustomerType] = useState(1);
    const[year, setYear] = useState(new Date().getFullYear());
    const[quarter, setQuarter] = useState(1);
    const[total, setTotal] = useState(0);
    const[open, setOpen] = useState(false);
    const[isSubmitted, setIsSubmitted] = useState(false);

    let years: ddOptions[] = [];
    for (let i = 2021; i <= new Date().getFullYear(); i++) {
        years.push({id: i, name: i.toString()});
    }  

    const GetFeeReport = useCallback(() => {
        if(account)
        {
          filters.userId = account.localAccountId;
          filters.year    = year;
          filters.quarter = quarter;
          instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
          }).then((response) => {
                   // console.log("before getreport:" + response.accessToken);
                    agent.report.getReport(account.localAccountId, filters, response.accessToken) //get a list of all pure components(pure and mixed), 
                        .then(feeReportResponse => {
                            console.log(feeReportResponse);
                            setFeeReports(feeReportResponse);
                            if(feeReportResponse[0]) {
                                setIsSubmitted((feeReportResponse[0].status === parseInt(eFeeReportStatus.Submitted.toString())) ? true : false);
                                setTotal(feeReportResponse[0].finalTotal);
                            } else {
                                setIsSubmitted(false);
                            }
                    })
            });
        }
    }, [account, instance, quarter, year, filters]);
   
    function DuplicateFeeReport(FeeReportId: number) {
      //  alert("Duplicate Fee Report");
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
          }).then((response) => {
                    console.log("before getreport:" + response.accessToken);
                    agent.report.duplicateFeeReport(FeeReportId, response.accessToken) //get a list of all pure components(pure and mixed), 
                        .then(reportId => {
                            GetReport(year, quarter, customerType);
                        })
        });

        
    }

    function DeleteFeeReport(FeeReportId: number) {
        //  alert("Duplicate Fee Report");
          instance.acquireTokenSilent({
              scopes: protectedResources.apiNPLTime.scopes,
              account: account
            }).then((response) => {
                      console.log("before delreport:" + response.accessToken);
                      agent.report.deleteReport(FeeReportId, response.accessToken) //get a list of all pure components(pure and mixed), 
                          .then(reportId => {
                                console.log(reportId);
                                GetReport(year, quarter, customerType);
                          })
          });
  
          
      }

    useEffect(() => {
        console.log("inside react effect");
        GetFeeReport();
        // if(account)
        // {
        //   filterValues.userId = account.localAccountId;
        //   instance.acquireTokenSilent({
        //     scopes: protectedResources.apiNPLTime.scopes,
        //     account: account
        //   }).then((response) => {
        //            // console.log("before getreport:" + response.accessToken);
                 
        //             agent.report.getReport(account.localAccountId, filterValues, response.accessToken) //get a list of all pure components(pure and mixed), 
        //                 .then(feeReportResponse => {
        //                     console.log(feeReportResponse);
        //                     setFeeReports(feeReportResponse);
        //                     if(feeReportResponse[0])
        //                         setTotal(feeReportResponse[0].finalTotal);
        //             })
        //     });
        // }
    }, [GetFeeReport]);

    const columnsCoLocCustomer = [
        { field: 'customerName', flex:0.5, headerName: 'Customer Name'},
        { field: 'location', flex:0.5,  headerName: 'Location' },
     
        { field: 'numberOfPorts', flex:0.5,  headerName: '# of Ports' },
        { field: 'mrc', flex:0.5,  headerName: 'MRC', renderHeader: (params: GridColumnHeaderParams) => ( <Tooltip title="Monthly Recurring Costs"><Box>MRC</Box></Tooltip> ), renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
        { field: 'firstBillingMonth', flex:0.5,  headerName: 'First Billing Month' },
        { field: 'monthlyNPLFee', flex:0.5,  headerName: 'Monthly NPL Fee', renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
        { field: 'total', flex:0.5,  headerName: 'Quarterly NPL Fee', renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
        

        { field : 'edit', flex:0.75, headerName:'Actions',
                renderCell: (params: GridCellParams) => {
                    const CreateDuplicateReport = () => {
                        let documentId: GridCellValue = params.getValue(params.id, "id");
                        DuplicateFeeReport(documentId != null ? +documentId : 0);

                    }
                    const DeleteReport = () => {
                        let documentId: GridCellValue = params.getValue(params.id, "id");
                        DeleteFeeReport(documentId != null ? +documentId : 0);
                    }
                    let readonly = (params.getValue(params.id, "status") === parseInt(eFeeReportStatus.Submitted.toString())) ? true : false;
                    return <div>
                        
                        <Button 
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }} 
                            href={`/CreateFeeReport?id=` + params.id} 
                        >
                            { readonly ? <>View</> : <>Edit</> }
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }}
                            disabled={(user.userType === eUserType.NPLAdmin) ? true : readonly}
                            onClick={DeleteReport}
                        >
                            Delete
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }} 
                            disabled={(user.userType === eUserType.NPLAdmin) ? true : readonly}
                            onClick= {CreateDuplicateReport}
                            //href={`/DuplicateReport?id=` + params.id} 
                        >
                            Duplicate
                        </Button>
                        
                    </div>
                }
        }
    ]

    const columnsEnterpriseCustomer = [
            { field: 'customerName', flex:0.5, headerName: 'Customer Name'},
            { field: 'location', flex:0.5,  headerName: 'Location' },
            { field: 'numberOfCertifiedEndPointDevices', flex:0.5,  headerName: '# of end point devices' },
            { field: 'numberOfSecondaryDistributionPoints', flex:0.5,  headerName: '# Secondary Distribution points' },
            { field: 'totalFeeForEndpointDevices', flex:0.5,  headerName: 'Monthly Fee for End Point Devices', renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
            { field: 'totalFeeForRedistributionPoints', flex:0.5,  headerName: 'Monthly Fee for Redistribution Points', renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
            { field: 'total', flex:0.5,  headerName: 'Monthly NPL Fee', renderCell: (params: GridCellParams) => { return (params.value != null) ? `£${params.value.toLocaleString()}` : '' } },
    
            { field : 'edit', flex:0.85, headerName:'Actions',
                    renderCell: (params: GridCellParams) => {
                        const CreateDuplicateReport = () => {
                            let documentId: GridCellValue = params.getValue(params.id, "id");
                            DuplicateFeeReport(documentId != null ? +documentId : 0);

                        }
                        const DeleteReport = () => {
                            let documentId: GridCellValue = params.getValue(params.id, "id");
                            DeleteFeeReport(documentId != null ? +documentId : 0);
                        }
                        let readonly = (params.getValue(params.id, "status") === parseInt(eFeeReportStatus.Submitted.toString())) ? true : false;
                        return <div>
                           
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 8 }} 
                                    href={`/CreateFeeReport?id=` + params.id} 
                                >
                                    { readonly ? <>View</> : <>Edit</> }
                                </Button>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 8 }}
                                    disabled={(user.userType === eUserType.NPLAdmin) ? true : readonly}
                                    onClick={DeleteReport}
                                >
                                    Delete
                                </Button>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 8 }} 
                                    disabled={(user.userType === eUserType.NPLAdmin) ? true : readonly}
                                    onClick= {CreateDuplicateReport}
                                    //href={`/DuplicateReport?id=` + params.id} 
                                >
                                Duplicate
                                </Button>
                           
                        </div>
                    }
                }
    ]

    const [value, setValue] = useState('1');

    const tableStyling = makeStyles({
        root: {
          "& .MuiDataGrid-columnsContainer": {
            maxHeight: "65px!important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            overflow: "visible",
            lineHeight: "1rem",
            whiteSpace: "normal",
          }
        }
    });
    
    const handleSelect = (event: any) => {
        //setValue(event.target.value);
        //alert(event.target.value);
        //alert(event.target.name);
      
        if(event.target.name === "year-select")
        {
            setYear(event.target.value)
            filterValues.year = event.target.value;
            //filterValues.year = event.target.value;        
            GetReport(event.target.value, quarter, customerType);
        }

        if(event.target.name === "quarter-select")
        {
            setQuarter(event.target.value);
            filterValues.quarter = event.target.value;
            
           // filterValues.quarter = event.target.value;  
           GetReport(year, event.target.value, customerType);
        }
        
        setFilters(filterValues);
    };

    const handleChange = (event: any) => {
       
        //alert(event.target.value);
        setValue(event.target.value);
      
        setCustomerType(event.target.value);
        setLoading(true);
        GetReport(year, quarter, event.target.value);
    }

    const DuplicateQuarter = () => {

        setOpen(true);

    }

    
    const handleClose = () => {
       setOpen(false);
    };

    const SubmitReport = () => {
        console.log(filters);
        if (account) {
            filters.userId = account.localAccountId;
                instance.acquireTokenSilent({
                    scopes: protectedResources.apiNPLTime.scopes,
                    account: account
            }).then((response) => {
                agent.report.submitReports(filters, response.accessToken)
                    .then(feeReportResponse => {
                        alert("Reports for this quarter have been submitted.");
                })
            });
        }
    }


    function GetReport(year: number, quarter: number, customerType: number)
    {
        setTotal(0);
        //alert("Calling get report");
        if(account)
        {
           filterValues.year =  year;
           filterValues.userId = account?.localAccountId;
           filterValues.quarter = quarter;
           filterValues.customerType = customerType;

          instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
          }).then((response) => {
                    //console.log("before GetReport" + response.accessToken);
                    agent.report.getReport(account.localAccountId, filterValues, response.accessToken) //get a list of all pure components(pure and mixed), 
                        .then(feeReportResponse  => {
                            console.log(feeReportResponse);
                            setLoading(false);
                            setFeeReports(feeReportResponse);
                            if(feeReportResponse[0])
                                setTotal(feeReportResponse[0].finalTotal);

                            //console.log(feeReportResponse);
                        });
                    });
        };
    };

    return (<div>
            <DuplicateFeeReportDialog open={open} onClose={handleClose} onUpdateGrid={GetFeeReport}  />
            <Box className='layout'>
            <Container fixed>
                     <Grid container className="titlePadding">
                        <Grid item xs={12} className="title"><NPLTimeText /></Grid>
                    </Grid> 
            </Container>
            </Box>  
             
            <Container className="middleContainer">

            <Typography component="h1" style={{ fontSize: '1.75em', fontWeight: 'bold' }}>Fee Reports</Typography>

            <p>
                As outlined in the <NPLTimeText /> Distributor Agreement, fee reports must be submitted by {user.companyName} on
                or before the 10th day of each calendar quarter to show the fees payable by {user.companyName} to NPL
                in relation to the previous quarter.  Upon submission of the fee report, NPL will issue an invoice quarterly
                on the last day of the month after the end of each quarter.  The fees payable by {user.companyName}, must be
                paid within 30 days after receipt of the NPL invoice. For more information on fee reporting or invoicing
                please contact: <a href="mailto:elena.parsons@npl.co.uk">elena.parsons@npl.co.uk</a> or <a href="mailto:tahir.maqba@npl.co.uk">tahir.maqba@npl.co.uk</a>.
            </p>

            <h4>Filters</h4>
            <Grid container>
                <Grid item xs = {3}>
                    <FormControl variant="standard" style={{ minWidth: 120 }}>                     
                        <InputLabel id="year-select">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            name="year-select"
                            value={year}
                            label="Year"
                            onChange={handleSelect}
                        >  
                        {years && years.map((e, key) => {
                            return <MenuItem key={key} value={e.id}>{e.name}</MenuItem>;
                        })}
                           
                        </Select>
                    
                        
                    </FormControl>
                </Grid>
                <Grid item xs = {3}>
                    <FormControl variant="standard" style={{ minWidth: 120 }}>
                       
                    
                        <InputLabel id="quarter-select">Quarter</InputLabel>
                        <Select
                            labelId="quarter-select-label"
                            id="quarter-select"
                            name="quarter-select"
                            label="Quarter"
                            value={quarter}
                            onChange={handleSelect}
                        >  
                            <MenuItem value={1}>Q1(Jan - Mar)</MenuItem>
                            <MenuItem value={2}>Q2(Apr - June)</MenuItem>
                            <MenuItem value={3}>Q3(July - Sept)</MenuItem>
                            <MenuItem value={4}>Q4(Oct - Dec)</MenuItem>
                        </Select>
                    
                        
                    </FormControl>
                </Grid>
            </Grid>  
              
                <FormControl component="fieldset">
                    <FormLabel component="legend"><h4>Customer Type</h4></FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlLabel value="1" control={<Radio />} label="Colocation Customer" style={{width:300}} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel value="2" control={<Radio />} label="Enterprise Customer" style={{width:300}} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
            
                </FormControl>
                    

            
                

                        
 
        <DataGrid rowHeight={70} style={{height:500}}
            className={tableStyling().root}
            getRowId={(row) => row.id} 
            rows={feeReports}
            // eslint-disable-next-line
            columns={customerType == 1 ? columnsCoLocCustomer : columnsEnterpriseCustomer}
            loading={loading}
            pagination
            //pageSize={5}
            //rowCount={100}
            
            components={{
            // Toolbar: CustomToolbar,
                Footer: props => {
                    return (
                         <div className="gridFooter">Quarterly Total: £{total.toLocaleString()}</div>
                        );
                },
            }}
        /> 

        
            <Button
                variant="contained"
                color="primary"
                size="small" 
                //className="btn"
                style={{ marginTop:10, marginBottom:10}}
                disabled={(user.userType === eUserType.NPLAdmin) ? true : isSubmitted}
                href={`/CreateFeeReport?id=0`} 
                >
                Create Entry
            </Button>

            <Button
                variant="contained"
                color="primary"
                size="small" 
                onClick={DuplicateQuarter}
                disabled={(user.userType === eUserType.NPLAdmin)}
                //className="btn"
                style={{ marginTop:10, marginBottom:10, marginLeft: 10}}
               //href={`/CreateFeeReport?id=0`} 
                >
                Duplicate Report From Previous Quarter
            </Button>

            <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={(user.userType === eUserType.NPLAdmin) ? true : isSubmitted}
                onClick={SubmitReport}
                //className="btn"
                style={{ marginTop:10, marginBottom:10, marginLeft: 10}}
               //href={`/CreateFeeReport?id=0`} 
                >
                Submit Quarter
            </Button>


    </Container> 
    </div>)

}



