import { useCallback, useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "../authconfig";
import agent from "../api/agent";
import { Box, CircularProgress, Grid, Paper } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';

const HubChart = () => {
    const [ datasets, setDataSets ] = useState<Highcharts.Options[]>([]);
    const [ loading, setLoading ]   = useState<boolean>(true);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {}) || undefined;

    const GetGraph = useCallback(() => {
        if(account)
        {

            instance.acquireTokenSilent({
                scopes: protectedResources.apiNPLTime.scopes,
                account: account
            }).then((response) => {
                agent.time.GetHubAvailabilityData(response.accessToken)
                .then((resp) => {
                    let dataDaisy1: [Date, number][] = [];
                    let dataDaisy2: [Date, number][] = [];
                    let dataTele1: [Date, number][] = [];
                    let dataTele2: [Date, number][] = [];

                    resp.forEach(e => {
                        if (e.label === 'Daisy') {
                            dataDaisy1.push([ new Date(e.timestamp), e.value1]);
                            dataDaisy2.push([ new Date(e.timestamp), e.value2]);
                        } else if (e.label === 'Telehouse') {
                            dataTele1.push([ new Date(e.timestamp), e.value1]);
                            dataTele2.push([ new Date(e.timestamp), e.value2]);
                        }
                    });

                    // data.sort((a:any, b:any) => a.timestamp - b.timestamp);

                    let hcConf = [
                        {
                            title: 'Daisy Loopback',
                            ptpData: dataDaisy1,
                            ppsData: dataDaisy2
                        },
                        {
                            title: 'Telehouse Loopback',
                            ptpData: dataTele1,
                            ppsData: dataTele2
                        }
                    ];

                    let dsc:Highcharts.Options[] = [];
                    for (let i = 0; i < hcConf.length; i++) {
                        // https://api.highcharts.com/highcharts/
                        dsc.push({
                            title: {
                                text: hcConf[i]['title'],
                            },
                            series: [{
                                name: 'PTP Offset',
                                type: 'line',
                                data: hcConf[i]['ptpData'],
                                color: "#404080",
                            },
                            {
                                name: 'PPS reference',
                                type: 'line',
                                data: hcConf[i]['ppsData'],
                                color: "#c28484"
                            }],
                            xAxis: {
                                title: { text: "Date" },
                                type: 'datetime',
                                labels: { format: '{value:%d/%m }' },
                            },
                            yAxis: {
                                title: { text: "Value (Nanoseconds)" },
                            },
                            tooltip: {
                                formatter: function() {
                                    const xVal = this.x as number;
                                    const cLabel = '';//`<span style="color:${this.series.color}">\u25CF</span>&nbsp`;
                                    return `${Highcharts.dateFormat('%Y-%m-%d, %H:%M:%S UTC', xVal, true)}<br/>${cLabel}${this.series.name}: <b>${this.y}</b>`;
                                },
                            },
                            chart: {
                                height: "400px",
                                zoomType: "x",
                            },
                            credits: {
                                enabled: false
                            },
                        });                  
                    }

                    setDataSets(dsc);
                    setLoading(false);
                });
            });
        }
    }, [account, instance]);

    useEffect(() => { GetGraph() }, [GetGraph]);
    
    Boost(Highcharts); // Greatly improves the load time of huge datasets.
    return(
        <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
                <Paper elevation={12} className="hubgraph">
                    {!loading ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={datasets[0]}
                        />
                    :
                        <Box height="400px" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress />
                        </Box>
                    }
                </Paper>
            </Grid>
            <Grid item xs={6} md={6}>
                <Paper elevation={12} className="hubgraph">
                    {!loading ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={datasets[1]}
                        />
                    :
                        <Box height="400px" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress />
                        </Box>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HubChart;
